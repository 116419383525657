// * Main imports
import React from "react";
import Particles from "react-particles-js";
// import { NextParticle } from "../functions/newNextParticle";
// import scrollTo from "gatsby-plugin-smoothscroll";

// * Assets
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
// import undefinedLogoWhite from "../images/undefinedLogoWhite.png";
// import "../styles/main.css";

const ParticleFunPage = () => {
  return (
    <main>
      <div
        style={{
          "scroll-snap-align": "start",
          height: "100vh",
          width: "100vw",
          backgroundColor: "black",
          position: "relative",
        }}
      >
        <Particles
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 99,
          }}
          params={{
            particles: {
              number: {
                value: 250,
                density: {
                  enable: false,
                },
              },
              color: {
                value: "#43de4c",
              },
              size: {
                value: 3,
                random: true,
              },
              move: {
                speed: 3,
              },
              line_linked: {
                enable: true,
                distance: 150,
                color: "#43de4c",
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
              modes: {
                remove: {
                  particles_nb: 10,
                },
              },
            },
          }}
        />
      </div>
    </main>
  );
};

export default ParticleFunPage;
